export const links = [
  {
    id: 1,
    name: 'About',
    url: '#about',
  },
  { id: 2, name: 'Projects', url: '#projects' },
  { id: 3, name: 'Tools', url: '#tools' },
  { id: 4, name: 'Contact', url: '#contact' },
];
